/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy of this
 * software and associated documentation files (the "Software"), to deal in the Software
 * without restriction, including without limitation the rights to use, copy, modify,
 * merge, publish, distribute, sublicense, and/or sell copies of the Software, and to
 * permit persons to whom the Software is furnished to do so.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED,
 * INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
 * PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT
 * HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION
 * OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE
 * SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
 */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthConfigurationService } from './../auth/auth-configuration.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent implements OnInit {
  tenantForm: FormGroup;
  params$: Observable<void>;
  error = false;
  errorMessage: string;


  // indication if to navigate automatically to a tenent that was already included in cache 
  // 
  navigateAutomatically: boolean = true

  constructor(
    private fb: FormBuilder,
    private authConfigService: AuthConfigurationService,
    private _snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.tenantForm = this.fb.group({
      tenantName: [null, [Validators.required]],
    });


    // just a thought.... 
    // since users might have a login problem (some old caching prevent login to cognito)
    // we might do not want to allow an automatic connection to an existing tenent, 
    // but just to pre-populate name... ???
    // 
    if (localStorage.getItem('tenantName')) {

      this.tenantForm.patchValue({tenantName:localStorage.getItem('tenantName')})

      // time to delay automatic navigation 
      var timeToWait = 4 * 1000

      var snackBarRef = this._snackBar.open("Navigation to "+ localStorage.getItem('tenantName') + ' ...', 'Cancel', {
        duration: timeToWait, // miliseconds
      });
      
      snackBarRef.onAction().subscribe(() => {
        //console.log('The snackbar action was triggered!');
        this.navigateAutomatically = false 
      });
      

      // trigger a delay autumatic navigation, so user get the chance to dismiss it 
      setTimeout(()=>{ 
        this.navigateAutomaticallyAction()
      }, timeToWait) // timeout structure      

       
    }
    
  }

  navigateAutomaticallyAction () { 
      if (this.navigateAutomatically) { 
        //this.router.navigate(['/dashboard']);
        this.router.navigate(['products','dashboard-list']);
        //this.router.navigate(['products', 'import']);
        //this.router.navigate(['/products/dashboard-list']);
        //this.router.navigate(['/products']);
        //this.router.navigate(['/products/list']);
        //this.router.navigate(['/']);
      }

  }

  isFieldInvalid(field: string) {
    const formField = this.tenantForm.get(field);
    return (
      formField && formField.invalid && (formField.dirty || formField.touched)
    );
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldInvalid(field),
    };
  }

  hasRequiredError(field: string) {
    return !!this.tenantForm.get(field)?.hasError('required');
  }

  openErrorMessageSnackBar(errorMessage: string) {
    this._snackBar.open(errorMessage, 'Dismiss', {
      duration: 4 * 1000, // seconds
    });
  }

  login() {
    let tenantName = this.tenantForm.value.tenantName;
    if (!tenantName) {
      this.errorMessage = 'No tenant name provided.';
      this.error = true;
      this.openErrorMessageSnackBar(this.errorMessage);
      return false;
    }

    this.authConfigService
      .setTenantConfig(tenantName)
      .then((val) => {
        //this.router.navigate(['/dashboard']);
        this.router.navigate(['products','dashboard-list']);
        //this.router.navigate(['products', 'import']);
        //this.router.navigate(['/products/dashboard-list']);
        //this.router.navigate(['/products']);
        //this.router.navigate(['/products/list']);
        //this.router.navigate(['/']);
        //this.router.navigate(['/']);

      })
      .catch((errorResponse) => {
        this.error = true;
        this.errorMessage =
          errorResponse.error.message || 'An unexpected error occurred!';
        this.openErrorMessageSnackBar(this.errorMessage);
      });

    return false;
  }
}
