<amplify-authenticator [hideSignUp]="true">
  <ng-template
    amplifySlot="authenticated"
    let-user="user"
  >
    <mat-sidenav-container fullscreen>
      <mat-sidenav
        [mode]="'side'"
        #drawer
        class="sidenav"
        fixedInViewport
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="(isHandset$ | async) === false"
      >
        <div class="sidebar-icon-container">
          <!--
          todo: handle datakama logo as .svg 
          <mat-icon svgIcon="saas-commerce" class="logo"></mat-icon>
          -->
          <img src="./assets/datakama-logo.png" alt="data*kama" width="100" height="50" >
        </div>
        <mat-divider></mat-divider>
        <mat-nav-list>
          <mat-list-item>
            ({{tenantName}})
          </mat-list-item>
          <mat-list-item *ngFor="let navItem of navItems">
            <mat-icon mat-list-icon class="nav-icon material-symbols-outlined">{{
              navItem.icon
            }}</mat-icon>
            <a mat-list-item routerLink="{{ navItem.url }}">{{ navItem.name }}</a>
          </mat-list-item>
        </mat-nav-list>
      </mat-sidenav>

      <mat-toolbar #toolbar class="sidenav-content-container">
        <button
          type="button"
          mat-icon-button
          (click)="drawer.toggle()"
          title="Open sidenav"
        >
          <mat-icon>menu</mat-icon>
        </button>
        <span>{{ (companyName$ | async) || "" }}</span>
        <span class="spacer"></span>
        <button
          mat-icon-button
          aria-label="account circle with outlined person icon"
          [matMenuTriggerFor]="useroptions"
        >
          <mat-icon class="material-symbols-outlined">person_filled</mat-icon>
        </button>
        <button
          mat-icon-button
          aria-label="account circle with outlined person icon"
          [matMenuTriggerFor]="settingsoptions"
        >
          <mat-icon class="material-symbols-outlined">settings</mat-icon>
        </button>
      </mat-toolbar>
      <mat-menu #useroptions="matMenu">
        <ng-template #loggedOut>
          <button mat-menu-item>
            <mat-icon class="material-symbols-outlined">lock_open</mat-icon>
            <span>Login</span>
          </button>
        </ng-template>
        <span style="margin: 8px">{{ (username$ | async) || user.username}}</span>
        <mat-divider></mat-divider>
        <button mat-menu-item>
          <mat-icon class="material-symbols-outlined">face</mat-icon>
          <span>Profile</span>
        </button>
        <button routerLink="/auth/info" mat-menu-item>
          <mat-icon class="material-symbols-outlined">construction</mat-icon>
          <span>Auth Debug</span>
        </button>
        <button (click)="logout()" mat-menu-item>
          <mat-icon class="material-symbols-outlined">logout</mat-icon>
          <span>Sign Out</span>
        </button>
      </mat-menu>
      <mat-menu #settingsoptions="matMenu">
        <button (click)="navigateToSuppliers()" mat-menu-item>
          <mat-icon class="material-symbols-outlined">buy</mat-icon>
          <span>Suppliers</span>
        </button>
        <button (click)="navigateToUsersList()" mat-menu-item>
          <mat-icon class="material-symbols-outlined">supervisor_account</mat-icon>
          <span>Users List</span>
        </button>
      </mat-menu>

      <div class="content" #main>
        <router-outlet></router-outlet>
      </div>

      <div class="footer" #footer>
        <div class="footer-text">
          <label *ngIf="!isProduction"> ------ Non-Prod ------ </label>
          <span>Menu Profit Manager &copy; 2024 Data*Kama</span>
          <label *ngIf="!isProduction"> ------ Non-Prod ------ </label>
          <!--
          <span class="spacer"></span>
          <span>
            Powered by SaaS Factory
          </span>
          -->
        </div>
      </div>
    </mat-sidenav-container>
  </ng-template>
</amplify-authenticator>
