<!-- <alert type="danger" *ngIf="error" [dismissible]="true">
  <p class="text-center"><strong>Oh snap!</strong> {{this.errorMessage}}</p>
</alert> -->
<div class="jumbotron jumbotron-fluid center-screen">
  <div>
    <form [formGroup]="tenantForm" (submit)="login()">
      <h1>
        <img src="./assets/datakama-logo.png" alt="data*kama" width="100" height="50">
      </h1>

      <mat-card class="card">
        <mat-card-title>Welcome! Please Connect</mat-card-title>
        <mat-card-subtitle>Enter your business name and click submit below</mat-card-subtitle>
        <mat-card-content>
          <mat-form-field appearance="outline">
            <mat-label>Business Name</mat-label>
            <input
            matInput
            type="tenantname"
            id="tenantname"
            name="tenantname"
            class="form-control"
            formControlName="tenantName"
            placeholder="Enter Business Name"
            [ngClass]="displayFieldCss('tenantName')"
            required
          />
            <mat-icon matSuffix>home</mat-icon>
          </mat-form-field>
          <mat-card-actions>
            <div class="button-panel">
              <button
                mat-raised-button
                color="primary"
                type="submit"
                [disabled]="!tenantForm.valid"
              >
                Submit
              </button>
            </div>
          </mat-card-actions>
        </mat-card-content>
      </mat-card>
    </form>
  </div>
</div>
