/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: MIT-0
 */
import { INavData } from './models';

export const navItems: INavData[] = [
  /*
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'insights',
  },
  */
  /*
  {
    name: 'Products',
    url: '/products',
    icon: 'sell',
  },
  */
  {
    name: 'Dashboard',
    url: '/products/dashboard-list',
    icon: 'insights',
  },
  {
    name: 'Cost List',
    url: '/products/dashboard-procurement',
    icon: 'attach_money',
  },
  {
    name: 'Materials',
    url: '/products/list/M',
    icon: 'sell',
  },
  {
    name: 'Recipes',
    url: '/products/list/R',
    icon: 'receipt_long',
  },
  {
    name: 'Dishes',
    url: '/products/list/D',
    icon: 'menu_book',
  },
  {
    name: 'Combinations',
    url: '/products/list/C',
    icon: 'join',
  },
  {
    name: 'Throwings',
    url: '/products/list/T',
    icon: 'delete_sweep',
  },  
  /*
  {
    name: 'Suppliers',
    url: '/suppliers',
    icon: 'buy',
  },
  */ 
  /*
  {
    name: 'Orders',
    url: '/orders',
    icon: 'shopping_cart',
  },
  */
  /*
  {
    name: 'Users',
    url: '/users',
    icon: 'supervisor_account',
  },
  */
];
